/* Tổng thể giao diện */
.app-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Lịch sử trò chuyện */
.chat-history {
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.chat-entry {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.chat-question,
.chat-answer {
  margin: 5px 0;
}

.chat-question {
  font-weight: bold;
  color: #333;
}

.chat-answer {
  background-color: #e9f5ff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.chat-link {
  margin-top: 5px;
}

.chat-link a {
  color: #007bff;
  text-decoration: none;
}

.chat-link a:hover {
  text-decoration: underline;
}

/* Phần nhập liệu */
.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  resize: none;
  height: 80px; /* Kích thước cao hơn cho textarea */
}

button {
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

/* Phần đánh giá */
.feedback-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.feedback-icon {
  font-size: 30px;
  margin: 0 15px;
  cursor: pointer;
  transition: transform 0.2s, color 0.2s;
}

.feedback-icon.selected {
  transform: scale(1.5);
}

.smile {
  color: #28a745; /* Xanh lá cho mặt cười */
}

.frown {
  color: #dc3545; /* Đỏ cho mặt buồn */
}

/* Phản hồi */
.feedback-message {
  text-align: center;
  color: #007bff;
  font-weight: bold;
}

.feedback-form {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #fff;
}

.feedback-form textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
  height: 80px; /* Kích thước cao hơn cho textarea */
}

.feedback-form button {
  margin-top: 10px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
}

.feedback-form button:hover {
  background-color: #218838;
}

/* Đề xuất câu hỏi */
.suggested-questions {
  margin-top: 20px;
}

/* Tối ưu hóa giao diện tổng thể */
.app-container {
  display: flex;
  flex-direction: column;
}
